<template>
  <div>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="grey lighten-2 white--text"
      ></v-breadcrumbs>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            @click="$router.go(-1)"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
          <div class="d-flex justify-end align-center mb-4">
            <v-chip
              class="mr-2"
              color="orange"
              v-if="
                solicitacao.statusCarta === 'Em aprovação' &&
                solicitacao.aprovadorId === user.funcionario.id
              "
              >Aguardando aprovação</v-chip
            >
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  depressed
                  color="fmq_gray"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Ações <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="cartaCriar && user.tipo !== 'Médico aprovador'"
                  @click="
                    dialogModelo = true;
                    buscarModelo(formDataModelo);
                  "
                >
                  <v-list-item-title>Buscar Modelo</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="
                    solicitacaoIniciarAtendimento &&
                    !checkStatus(
                      [
                        'Pendente de aceite',
                        'Aberto',
                        'Consulta interna',
                        'Finalizado',
                      ],
                      solicitacao.status
                    ) &&
                    solicitacaoEncaminhar
                  "
                  @click="dialogLiberar = true"
                >
                  <v-list-item-title
                    >Liberar para atendimento</v-list-item-title
                  >
                </v-list-item>

                <v-list-item
                  @click="dialogEncaminhar = true"
                  v-if="
                    checkStatus(
                      ['Aberto', 'Em andamento', 'Troca de mesa'],
                      solicitacao.status
                    ) && solicitacaoEncaminhar
                  "
                >
                  <v-list-item-title
                    >Encaminhar para outra mesa</v-list-item-title
                  >
                </v-list-item>

                <v-list-item
                  @click="
                    dialogAprovacao = true;
                    formDataAprovacao.aprovadorId =
                      solicitacao.produto.aprovadorIc;
                  "
                  v-if="
                    !checkStatus(
                      [
                        'Pendente de aceite',
                        'Aberto',
                        'Consulta interna',
                        'Finalizado',
                      ],
                      solicitacao.status
                    ) && solicitacaoEnviarAprovacao
                  "
                >
                  <v-list-item-title
                    >Encaminhar para aprovação</v-list-item-title
                  >
                </v-list-item>

                <v-list-item
                  @click="abrirPDF()"
                  v-if="
                    solicitacao.mesasId === 1 &&
                    user.tipo !== 'Médico aprovador'
                  "
                >
                  <v-list-item-title
                    >Visualizar carta resposta</v-list-item-title
                  >
                </v-list-item>

                <v-list-item
                  @click="dialogFinalizarEnviar = true"
                  v-if="
                    solicitacao.mesasId === 1 &&
                    (checkStatus(['Aprovada'], solicitacao.statusCarta) ||
                      this.solicitacao.modelosId) &&
                    solicitacaoFinalizarEnviar
                  "
                >
                  <v-list-item-title
                    >Gerar PDF e enviar carta resposta</v-list-item-title
                  >
                </v-list-item>

                <v-list-item
                  v-if="solicitacao.mesasId === 2 && solicitacaoFinalizarEnviar"
                  @click="dialogFinalizarEnviar = true"
                >
                  <v-list-item-title>Enviar resposta</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="solicitacaoFinalizarEnviar"
                  @click="dialogFinalizar = true"
                >
                  <v-list-item-title>Finalizar sem resposta</v-list-item-title>
                </v-list-item>

                <template
                  v-if="
                    checkStatus(['Em aprovação'], solicitacao.status) &&
                    solicitacaoResponderAprovacao &&
                    solicitacao.aprovadorId === user.funcionario.id
                  "
                >
                  <v-list-item
                    @click="
                      dialogResponderAprovacao = true;
                      formDataResponderAprovacao.aprovado = 1;
                    "
                  >
                    <v-list-item-title>Aprovar carta</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="
                      dialogResponderAprovacao = true;
                      formDataResponderAprovacao.aprovado = 0;
                    "
                  >
                    <v-list-item-title
                      >Solicitar alteração na carta</v-list-item-title
                    >
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-row>
          <v-col cols="8">
            <SolicitacoesResumo
              class="mb-8"
              :solicitacao="solicitacao"
              :listLinhas="listLinhas"
              :listMedicosSolicitantes="listMedicosSolicitantes"
            />
            <SolicitacaoCartaResposta
              :solicitacao="solicitacao"
              :listTags="listTags"
              :listCategorias="listCategorias"
              :listArtigos="listArtigos"
              :listProdutos="listProdutos"
              :modelo="modelo"
              :viewOnly="false"
              @send="editar"
              @buscarArtigo="
                dialogArtigo = true;
                buscarArtigo(formDataArtigo);
              "
              :loading="loadingBtn"
              :artigos="artigosSelecionados"
              @excluirArtigo="excluirArtigo"
            />
          </v-col>
          <v-col cols="4">
            <SolicitacoesComentarios
              :comentarios="solicitacao.comentarios"
              @send="comentar"
              :loading="loadingCometario"
              @deletar="
                dialogExcluir = true;
                comentarioId = $event;
              "
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <AlertConfirmation
      :dialog="dialogExcluir"
      dialogMessage="Tem certeza que deseja excluir esse comentário?"
      @close="dialogExcluir = false"
      @accept="excluir"
    />
    <AlertConfirmation
      :dialog="dialogLiberar"
      dialogMessage="Tem certeza que deseja libera essa solicitação para um novo atendimento?"
      @close="dialogLiberar = false"
      @accept="liberar"
    />
    <AlertSuccess
      :dialog="success"
      dialogMessage="Ação concluída com sucesso"
      @close="
        exibir();
        success = false;
      "
    />
    <AlertError :alertError="error" :messageError="messageError" />
    <FormModal
      :loading="loadingEncaminhar"
      :dialog="dialogEncaminhar"
      dialogMessage="Encaminhar solicitação"
      @close="dialogEncaminhar = false"
      @accept="encaminhar"
    >
      <v-form ref="formEncaminhar" v-model="valid" lazy-validation>
        <v-textarea
          outlined
          label="Comentário"
          rows="2"
          v-model="formDataEncaminhar.comentario"
          required
          :rules="rules.genericRules"
        ></v-textarea>
        <v-switch
          v-model="formDataEncaminhar.visivelApp"
          label="Comentário visível para o representante?"
          inset
          :hide-details="true"
          class="mt-0 pa-0 mb-4"
          color="cyan"
        ></v-switch>
      </v-form>
    </FormModal>
    <FormModal
      :loading="loadingFinalizar"
      :dialog="dialogFinalizar"
      dialogMessage="Finalizar solicitação sem resposta"
      @close="dialogFinalizar = false"
      @accept="finalizar"
    >
      <v-form ref="formFinalizar" v-model="valid" lazy-validation>
        <v-textarea
          outlined
          label="Comentário"
          rows="2"
          v-model="formDataFinalizar.comentario"
          required
          :rules="rules.genericRules"
        ></v-textarea>
      </v-form>
    </FormModal>
    <TableModal
      :dialog="dialogModelo"
      @close="dialogModelo = false"
      dialogMessage="Lista de Modelos"
    >
      <ModelosTabelaBuscar
        :headers="headersModelos"
        :itens="modelos"
        :total="total"
        :pageText="pageText"
        :pageNumber="page"
        :loading="loadingModelos"
        @handleFilter="filter"
        @selecionar="selecionar"
      >
        <template v-slot:titulo>
          <v-text-field
            v-model="formDataModelo.titulo"
            placeholder="Busque pelo titulo"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscarModelo(formDataModelo);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`produtos`]>
          <v-autocomplete
            v-model="formDataModelo.produtosId"
            :items="listProdutos"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pelo produto"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscarModelo(formDataModelo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:assunto>
          <v-text-field
            v-model="formDataModelo.assunto"
            placeholder="Busque pelo movito"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscarModelo(formDataModelo);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`categorias`]>
          <v-autocomplete
            v-model="formDataModelo.categoriasId"
            :items="listCategorias"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pelo produto"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscarModelo(formDataModelo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:tags>
          <v-autocomplete
            v-model="formDataModelo.tagsId"
            :items="listTags"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pela tag"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              formDataModelo(formDataModelo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </ModelosTabelaBuscar>
    </TableModal>
    <FormModal
      :loading="loadingAprovacao"
      :dialog="dialogAprovacao"
      dialogMessage="Encaminha para aprovação"
      @close="dialogAprovacao = false"
      @accept="aprovacao"
    >
      <v-form ref="formAprovacao" v-model="valid" lazy-validation>
        <v-autocomplete
          v-model="formDataAprovacao.aprovadorId"
          :items="listMedicoAprovadores"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          label="Médico aprovador"
          placeholder="Médico aprovador"
          outlined
          dense
          small-chips
          required
          :rules="rules.selectRules"
          deletable-chips
        ></v-autocomplete>
        <v-textarea
          outlined
          label="Comentário"
          rows="2"
          v-model="formDataAprovacao.comentario"
          required
          :rules="rules.genericRules"
        ></v-textarea>
      </v-form>
    </FormModal>
    <AlertConfirmation
      :dialog="dialogFinalizarEnviar"
      :thirdButton="true"
      dialogMessage="Tem certeza que deseja finalizar a carta e enviar?"
      @close="dialogFinalizarEnviar = false"
      @accept="enviarCartaResposta(false)"
      @thirdEvent="enviarCartaResposta(true)"
      icon=""
      thirdButtonText="Enviar e criar modelo"
    />
    <FormModal
      :dialog="dialogFinalizarSemAprovacao"
      dialogMessage="Envio sem aprovação"
      @close="dialogFinalizarSemAprovacao = false"
      @accept="enviarCartaAprovada(formDataFinalizarSemAprovacao.comentario)"
    >
      <v-form ref="formFinalizarSemAprovacao" v-model="valid" lazy-validation>
        <v-textarea
          outlined
          label="Comentário"
          rows="2"
          v-model="formDataFinalizarSemAprovacao.comentario"
          required
          placeholder="Motivo do envio sem aprovação"
          :rules="rules.genericRules"
        ></v-textarea>
      </v-form>
    </FormModal>

    <FormModal
      :loading="loadingResponderAprovacao"
      :dialog="dialogResponderAprovacao"
      dialogMessage="Responder solicitação de aprovação"
      @close="dialogResponderAprovacao = false"
      @accept="responderAprovacao"
    >
      <v-form ref="formResponderAprovacao" v-model="valid" lazy-validation>
        <v-textarea
          outlined
          label="Comentário"
          rows="2"
          v-model="formDataResponderAprovacao.comentario"
          required
          placeholder="Comentário sobre a solicitação"
          :rules="rules.genericRules"
        ></v-textarea>
      </v-form>
    </FormModal>
    <TableModal
      :dialog="dialogArtigo"
      @close="dialogArtigo = false"
      dialogMessage="Lista de Artigos"
    >
      <ArtigosTabelaBuscar
        :headers="headersArtigos"
        :itens="artigos"
        :total="total"
        :pageText="pageText"
        :pageNumber="page"
        :loading="loadingArtigos"
        @handleFilter="filterArtigo"
        @selecionar="selecionarArtigo"
      >
        <template v-slot:produtos>
          <v-autocomplete
            v-model="formDataArtigo.produtosId"
            :items="listProdutos"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pelo produto"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:autor>
          <v-text-field
            v-model="formDataArtigo.autor"
            placeholder="Busque pelo autor"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:dataPublicacao>
          <v-autocomplete
            v-model="formDataArtigo.dataPublicacao"
            :items="listDatas"
            hide-no-data
            hide-selected
            item-text="value"
            item-value="value"
            placeholder="Busque pelo ano"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:categorias>
          <v-autocomplete
            v-model="formDataArtigo.categoriasId"
            :items="listCategorias"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pela categoria"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:titulo>
          <v-text-field
            v-model="formDataArtigo.titulo"
            placeholder="Busque pelo titulo"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:tags>
          <v-autocomplete
            v-model="formDataArtigo.tagsId"
            :items="listTags"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pela tag"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:link>
          <v-text-field
            solo-inverted
            flat
            dense
            :hide-details="true"
            disabled
            readonly
          >
          </v-text-field>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn
              class="mr-2"
              outlined
              x-small
              fab
              color="red"
              @click="clearArtigo"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="searchArtigo">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </ArtigosTabelaBuscar>
    </TableModal>
  </div>
</template>

<script>
import { hashCodeModelo } from "@/utils";
import ModelosTabelaBuscar from "@/components/modelos/ModelosTabelaBuscar.vue";
import ArtigosTabelaBuscar from "@/components/artigos/ArtigosTabelaBuscar.vue";
import { rules } from "@/utils/rules.js";
import SolicitacaoCartaResposta from "@/components/solicitacoes/SolicitacaoCartaResposta.vue";
import SolicitacoesComentarios from "@/components/solicitacoes/SolicitacoesComentarios.vue";
import SolicitacoesResumo from "@/components/solicitacoes/SolicitacoesResumo.vue";
import {
  exibirSolicitacao,
  inserirComentarioSolicitacao,
  excluirComentarioSolicitacao,
  encaminharSolicitacao,
  finalizarSemRespostaSolicitacao,
  deixarAtentimentoSolicitacao,
  encaminharAprovacaSolicitacao,
  finalizarEnviarSolicitacao,
  responderAprovacaoSolicitacao,
} from "@/services/solicitacoes";
import { buscarModelos } from "@/services/modelos.js";
import { listarLinha } from "@/services/linhas";
import { listarMedicoSolicitante } from "@/services/medico-solicitante";
import { listarTags } from "@/services/tags";
import { listarArtigos, buscarArtigos } from "@/services/artigos.js";
import { listarProdutos } from "@/services/produtos.js";
import { listarCategoria } from "@/services/categorias.js";
import {
  editarCarta,
  previewPdfCarta,
  convertEmModeloCarta,
} from "@/services/carta.js";
import { listarMedicoAprovadores } from "@/services/user";
export default {
  name: "CartasRespostaEditar",
  components: {
    SolicitacoesComentarios,
    SolicitacoesResumo,
    SolicitacaoCartaResposta,
    ModelosTabelaBuscar,
    ArtigosTabelaBuscar,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Lista de Solicitações",
        disabled: false,
        to: "/",
      },
      {
        text: "Editar Carta Resposta",
        disabled: true,
        to: "",
      },
    ],
    loading: false,
    solicitacao: {},
    error: false,
    success: false,
    messageError: null,
    listLinhas: [],
    listMedicosSolicitantes: [],
    listTags: [],
    listArtigos: [],
    listProdutos: [],
    listCategorias: [],
    listDatas: [],
    modelo: {},
    dialogExcluir: false,
    loadingBtn: false,
    loadingCometario: false,
    comentarioId: null,
    rules: rules,
    valid: true,
    formDataEncaminhar: {
      comentario: null,
      visivelApp: false,
    },
    dialogEncaminhar: false,
    loadingEncaminhar: false,
    formDataFinalizar: {
      comentario: null,
    },
    dialogFinalizar: false,
    loadingFinalizar: false,
    dialogLiberar: false,
    dialogModelo: false,
    headersModelos: [
      { text: "Modelo", value: "titulo", sortable: false },
      { text: "Produtos", value: "produtos", sortable: false },
      { text: "Motivo", value: "assunto", sortable: false },
      { text: "Categorias", value: "categorias", sortable: false },
      { text: "Tags", value: "tags", sortable: false },
      { text: "Ações", value: "id", sortable: false },
    ],
    modelos: [],
    total: null,
    pageText: null,
    loadingModelos: false,
    page: 1,
    formDataModelo: {
      orderBy: null,
      orderSorted: null,
      perPage: 10,
      produtosId: null,
      categoriasId: null,
      titulo: null,
      tagsId: null,
      assunto: null,
      mesasId: 1,
    },
    formDataAprovacao: {
      comentario: null,
      aprovadorId: [],
    },
    dialogAprovacao: false,
    loadingAprovacao: false,
    listMedicoAprovadores: [],
    dialogFinalizarEnviar: false,
    gerarModelo: null,
    dialogFinalizarSemAprovacao: false,
    formDataFinalizarSemAprovacao: {
      comentario: null,
    },
    dialogResponderAprovacao: false,
    formDataResponderAprovacao: {
      comentario: null,
      aprovado: null,
    },
    loadingResponderAprovacao: false,
    dialogArtigo: false,
    artigos: [],
    loadingArtigos: false,
    formDataArtigo: {
      orderBy: null,
      orderSorted: null,
      perPage: 10,
      produtosId: null,
      autor: null,
      dataPublicacao: null,
      titulo: null,
      tagsId: null,
      categoriasId: null,
    },
    headersArtigos: [
      { text: "Titulo", value: "titulo" },
      { text: "Autor", value: "autor", sortable: false },
      { text: "Categorias", value: "categorias", sortable: false },
      { text: "Tags", value: "tags", sortable: false },
      { text: "Produtos", value: "produtos", sortable: false },
      { text: "Ano", value: "dataPublicacao", sortable: false },
      { text: "Link", value: "link", sortable: false },
      { text: "Ações", value: "id", sortable: false },
    ],
    artigosSelecionados: [],
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.getProdutos();
    this.getArtigos();
    this.getCategorias();
    this.getTags();
    this.getLinhas();
    this.getMedicosSolicitantes();
    this.exibir().then(() => {
      if (
        this.checkStatus(
          [
            "Pendente de aceite",
            "Finalizado",
            "Aguardando resposta",
            "Respondido",
            "Consulta interna",
          ],
          this.solicitacao.status
        )
      ) {
        this.$router.push({ name: "Solicitacoes" });
      }
    });
    this.getMedicoAprovadores();
    this.listDatas = this.createYearArray();
  },
  methods: {
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await exibirSolicitacao(this.$route.params.id);
        this.solicitacao = resp.data;
        if (this.solicitacao.mesasId === 2) {
          this.breadcrumbs[0].text = "Lista de Treinamentos";
          this.breadcrumbs[0].to = "/treinamento";
          this.formDataModelo.mesasId = 2;
        }
        this.formDataModelo.produtosId = this.solicitacao.produtosId;
        this.formDataArtigo.produtosId = this.solicitacao.produtosId;
        this.solicitacao.carta.artigos.forEach((artigo) => {
          this.selecionarArtigo(artigo);
        });
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async editar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarCarta(this.solicitacao.carta.id, event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async comentar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingCometario = true;
      try {
        await inserirComentarioSolicitacao(this.$route.params.id, event).then(
          () => {
            this.success = true;
          }
        );
        this.loadingCometario = false;
      } catch (e) {
        this.loadingCometario = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await excluirComentarioSolicitacao(this.comentarioId).then(() => {
          this.dialogExcluir = false;
          this.success = true;
        });
      } catch (e) {
        this.dialogExcluir = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async encaminhar() {
      if (this.formValidEncaminhar) {
        this.error = false;
        this.sucess = false;
        this.loadingEncaminhar = true;
        try {
          await encaminharSolicitacao(
            this.$route.params.id,
            this.formDataEncaminhar
          ).then(() => {
            this.dialogEncaminhar = false;
            this.success = true;
          });
          this.loadingEncaminhar = false;
        } catch (e) {
          this.loadingEncaminhar = false;
          this.error = true;
          this.messageError = e.response.data.message;
        }
      }
    },
    async finalizar() {
      if (this.formValidFinalizar) {
        this.error = false;
        this.sucess = false;
        this.loadingFinalizar = true;
        try {
          await finalizarSemRespostaSolicitacao(
            this.$route.params.id,
            this.formDataFinalizar
          ).then(() => {
            this.dialogFinalizar = false;
            this.success = true;
          });
          this.loadingFinalizar = false;
        } catch (e) {
          this.loadingFinalizar = false;
          this.error = true;
          this.messageError = e.response.data.message;
        }
      }
    },
    async finalizarEnviar(payload) {
      try {
        await finalizarEnviarSolicitacao(this.$route.params.id, payload);
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async liberar() {
      this.error = false;
      try {
        await deixarAtentimentoSolicitacao(this.$route.params.id).then(() => {
          this.dialogLiberar = false;
          this.success = true;
        });
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async buscarModelo(payload) {
      this.modelos = [];
      try {
        this.loadingModelos = true;
        const resp = await buscarModelos(payload, this.page);
        this.modelos = resp.data.data;
        this.total = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loadingModelos = false;
      } finally {
        this.loadingModelos = false;
      }
    },
    async buscarArtigo(payload) {
      this.artigos = [];
      try {
        this.loadingArtigos = true;
        const resp = await buscarArtigos(this.page, payload);
        this.artigos = resp.data.data;
        this.total = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loadingArtigos = false;
      } finally {
        this.loadingArtigos = false;
      }
    },
    async aprovacao() {
      if (this.formValidAprovacao) {
        this.error = false;
        this.sucess = false;
        this.loadingAprovacao = true;
        try {
          await encaminharAprovacaSolicitacao(
            this.$route.params.id,
            this.formDataAprovacao
          ).then(() => {
            this.dialogMessage = "Solicitação encaminhada com sucesso";
            this.dialogAprovacao = false;
            this.success = true;
          });
          this.loadingAprovacao = false;
        } catch (e) {
          this.loadingAprovacao = false;
          this.error = true;
          this.messageError = e.response.data.message;
        }
      }
    },
    async abrirPDF() {
      try {
        await previewPdfCarta(this.solicitacao.carta.id).then((resp) => {
          window.open(resp.data.link, "_blank");
        });
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async getLinhas() {
      const resp = await listarLinha();
      this.listLinhas = resp.data;
    },
    async getMedicosSolicitantes() {
      const resp = await listarMedicoSolicitante();
      this.listMedicosSolicitantes = resp.data;
    },
    async getTags() {
      const resp = await listarTags();
      this.listTags = resp.data;
    },
    async getArtigos() {
      const resp = await listarArtigos();
      this.listArtigos = resp.data;
    },
    async getProdutos() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async getCategorias() {
      const resp = await listarCategoria();
      this.listCategorias = resp.data;
    },
    async getMedicoAprovadores() {
      const resp = await listarMedicoAprovadores();
      this.listMedicoAprovadores = resp.data;
    },
    checkStatus(status, status_check) {
      return status.indexOf(status_check) >= 0;
    },
    validateEncaminhar() {
      this.$refs.formEncaminhar.validate();
    },
    validateFinalizar() {
      this.$refs.formFinalizar.validate();
    },
    validateFinalizarSemArpovacao() {
      this.$refs.formFinalizarSemArpovacao.validate();
    },
    validateAprovacao() {
      this.$refs.formAprovacao.validate();
    },
    validateResponderAprovacao() {
      this.$refs.formResponderAprovacao.validate();
    },
    clear() {
      this.formDataModelo.produtosId = null;
      this.formDataModelo.autor = null;
      this.formDataModelo.dataPublicacao = null;
      this.formDataModelo.titulo = null;
      this.formDataModelo.tagsId = null;
      this.page = 1;
      this.buscarModelo(this.formDataModelo);
    },
    search() {
      this.page = 1;
      this.buscarModelo(this.formDataModelo);
    },
    filter(event) {
      this.formDataModelo.orderBy = event.orderBy;
      this.formDataModelo.orderSorted = event.orderSorted;
      this.formDataModelo.perPage = event.perPage;
      this.page = event.page;
      this.buscarModelo(this.formDataModelo);
    },
    clearArtigo() {
      this.formDataArtigo.produtosId = null;
      this.formDataArtigo.autor = null;
      this.formDataArtigo.dataPublicacao = null;
      this.formDataArtigo.titulo = null;
      this.formDataArtigo.tagsId = null;
      this.formDataArtigo.categoriasId = null;
      this.page = 1;
      this.buscarArtigo(this.formDataArtigo);
    },
    searchArtigo() {
      this.page = 1;
      this.buscarArtigo(this.formDataArtigo);
    },
    filterArtigo(event) {
      this.formDataArtigo.orderBy = event.orderBy;
      this.formDataArtigo.orderSorted = event.orderSorted;
      this.formDataArtigo.perPage = event.perPage;
      this.page = event.page;
      this.buscarArtigo(this.formDataArtigo);
    },
    selecionar(event) {
      this.dialogModelo = false;
      this.modelo = event;
      this.modelo.artigos.forEach((artigo) => {
        this.selecionarArtigo(artigo);
      });
    },
    selecionarArtigo(event) {
      this.dialogArtigo = false;
      const artigo = this.artigosSelecionados.find(
        (item) => item.id === event.id
      );
      if (!artigo) {
        this.artigosSelecionados.push(event);
      }
    },
    excluirArtigo(event) {
      const indice = this.artigosSelecionados.indexOf(event);
      if (indice !== -1) {
        this.artigosSelecionados.splice(indice, 1);
      }
    },
    enviarCartaResposta(gerarModelo) {
      this.gerarModelo = gerarModelo;
      if (this.solicitacao.mesasId === 1) {
        if (
          this.sameHash ||
          this.checkStatus(["Aprovada"], this.solicitacao.statusCarta)
        ) {
          this.enviarCartaAprovada("");
        } else if (
          !this.checkStatus(["Aprovada"], this.solicitacao.statusCarta) &&
          !this.sameHash
        ) {
          this.dialogFinalizarSemAprovacao = true;
        }
      } else if (this.solicitacao.mesasId === 2) {
        this.enviarCartaAprovada("");
      }
    },
    async enviarCartaAprovada(comentario) {
      await this.finalizarEnviar({
        id: this.$route.params.id,
        comentario: comentario,
      }).then(() => {
        if (this.gerarModelo) {
          this.converterEmModelo();
        }
        this.dialogFinalizarEnviar = false;
        this.dialogFinalizarSemAprovacao = false;
        this.success = true;
      });
    },
    async converterEmModelo() {
      this.dialogModelo = false;
      this.error = false;
      try {
        await convertEmModeloCarta(this.solicitacao.carta.id);
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async responderAprovacao() {
      if (this.formValidResponderAprovacao) {
        this.error = false;
        this.sucess = false;
        this.loadingResponderAprovacao = true;
        try {
          await responderAprovacaoSolicitacao(
            this.$route.params.id,
            this.formDataResponderAprovacao
          ).then(() => {
            this.dialogMessage = "Solicitação encaminhada com sucesso";
            this.dialogResponderAprovacao = false;
            this.success = true;
          });
          this.loadingResponderAprovacao = false;
        } catch (e) {
          this.dialogResponderAprovacao = false;
          this.loadingResponderAprovacao = false;
          this.error = true;
          this.messageError = e.response.data.message;
        }
      }
    },
    createYearArray() {
      const currentYear = new Date().getFullYear();
      const startYear = 1990;
      const yearArray = [];

      for (let year = startYear; year <= currentYear; year++) {
        yearArray.push({ value: year });
      }

      return yearArray;
    },
  },
  computed: {
    cartaCriar() {
      return this.$store.state.user.data.permissoes.includes("carta.criar");
    },
    solicitacaoEnviarAprovacao() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.enviarAprovacao"
      );
    },
    solicitacaoEncaminhar() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.encaminhar"
      );
    },
    solicitacaoIniciarAtendimento() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.iniciarAtendimento"
      );
    },
    solicitacaoFinalizarEnviar() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.finalizarEnviar"
      );
    },
    solicitacaoResponderAprovacao() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.responderAprovacao"
      );
    },
    formValidEncaminhar() {
      return this.$refs.formEncaminhar.validate();
    },
    formValidFinalizar() {
      return this.$refs.formFinalizar.validate();
    },
    formValidFinalizarSemAprovacao() {
      return this.$refs.formFinalizarSemAprovacao.validate();
    },
    formValidAprovacao() {
      return this.$refs.formAprovacao.validate();
    },
    formValidResponderAprovacao() {
      return this.$refs.formResponderAprovacao.validate();
    },
    user() {
      return this.$store.state.user.data;
    },
    mesaId() {
      return this.$store.state.user.data.funcionario.mesaId;
    },
    sameHash() {
      if (this.solicitacao.modelo === null){
        return false;
      } 

      return (
        hashCodeModelo(
          JSON.stringify({
            descricao: this.solicitacao.carta.resumo,
            titulo: this.solicitacao.carta.titulo,
            conteudo: this.solicitacao.carta.conteudo,
          })
        ) ===
        hashCodeModelo(
          JSON.stringify({
            descricao: this.solicitacao.modelo.descricao,
            titulo: this.solicitacao.modelo.titulo,
            conteudo: this.solicitacao.modelo.conteudo,
          })
        )
      );
    },
  },
};
</script>

<style></style>
